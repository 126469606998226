<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more" />
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :command="'markAsActive'">
            Mark as active</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <eden-confirm-dialog
      v-if="dialog"
      :title="dialog.title"
      :button-text="dialog.button"
      :button-type="dialog.buttonType"
      :button-status="action.state"
      :show="action.visibility"
      @confirm="setAction"
      @close="closeConfirmDialog"
    >
      <p class="mb-2">
        {{ dialog.message }}
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
export default {
  name: "CustomerActions",
  props: {
    id: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      deleting: false,
      action: {
        command: "",
        state: false,
        visibility: false,
      },
      dialogs: {
        markAsActive: {
          title: "Mark Vendor as Active?",
          button: "Mark As Active",
          buttonType: "primary",
          message: `This action would mark the current vendor as active and able to sell ?`,
        },
      },
    };
  },
  computed: {
    dialog() {
      return this.dialogs[this.action.command];
    },
  },
  methods: {
    command(cmd) {
      this.action.command = cmd;
      this.action.state = false;
      setTimeout(() => {
        this.action.visibility = true;
      }, 200);
    },
    closeConfirmDialog() {
      this.action.visibility = false;
    },
    setAction() {
      switch (this.action.command) {
        case "markAsActive":
          this.markVendorAsActive();
          break;

        default:
          break;
      }
      this.action.visibility = false;
      this.$emit("markAsInactive");
    },
    markVendorAsActive() {
      this.deleting = true;
      this.$store
        .dispatch(actions.TOGGLE_VENDOR_ACTIVE_STATE, {
          id: this.$props.id,
          status: "Approved",
        })
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("success", this.$props.id);
        })
        .catch((error) => {
          this.$message.errror(error.data.message);
        });
    },
    emitUpdate(data) {
      const { command } = this.action;
      const fetch = [
        "pause-orders",
        "create-orders",
        "delete-orders",
        "set-cycle",
        "delete",
      ];

      const { id } = this.$route.params;
      if (id && command === "delete") {
        return;
      }
      if (fetch.includes(command)) {
        this.$emit("fetch");
      } else {
        this.$emit("update", data);
      }
    },
  },
};
</script>

<style lang="scss">
.customer-actions {
  max-height: 200px;
  overflow-y: scroll;

  .section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      padding: 0 20px;
      margin: 10px 0;
      color: var(--eden-grey-primary);
      text-transform: uppercase;
      font-size: 0.825rem;
      font-weight: 600;
    }
  }
}
</style>
