<template>
  <div>
    <el-button
      type="plain"
      icon="eden-icon-upload"
      :disabled="loading"
      @click="showExport = true"
    >
      Export
    </el-button>
    <el-dialog
      title="Export"
      :visible.sync="showExport"
      @close="closeEvent"
      width="30%"
    >
      <el-form label-position="top" :model="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Select data format">
              <el-radio-group v-model="form.format">
                <el-radio
                  v-for="(key, value, i) in formats"
                  :key="i"
                  :label="value"
                >
                  {{ key }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="is-flex justify-end">
        <el-button
          type="primary"
          :loading="exporting"
          @click="exportAction"
          :disabled="!form.format"
          >Export</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import vendors from "@/requests/vendors/active";

export default {
  name: "DirectoryRidersExport",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "active",
    },
    searchQuery: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showExport: false,
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xlsx: "Excel",
      },
      form: {
        format: "",
      },
      exporting: false,
    };
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.format = "";
      this.exporting = false;
      this.showExport = false;
    },

    exportAction() {
      this.exporting = true;
      let payload;
      if (this.searchQuery) {
        payload = {
          status: this.status,
          search: this.searchQuery,
          export_format: this.form.format,
        };
      } else {
        payload = {
          status: this.status,
          export_format: this.form.format,
        };
      }
      vendors
        .export(payload)
        .then((response) => {
          this.exporting = false;
          this.$message.success(response.data.message);
        })
        .catch((error) => {
          this.exporting = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadComboCsv,
.downloadComboXls {
  position: absolute;
  opacity: 0;
}
</style>
