import { render, staticRenderFns } from "./vendors-inactive.vue?vue&type=template&id=91ff5b5e&scoped=true&"
import script from "./vendors-inactive.vue?vue&type=script&lang=js&"
export * from "./vendors-inactive.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ff5b5e",
  null
  
)

export default component.exports