<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <!-- <customers-individual-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        /> -->
        <vendors-export status="Approved" :search-query="searchQuery" />
      </template>
    </eden-table-actions>

    <eden-loader v-if="loading" />

    <template v-else>
      <el-table :data="pageData" :key="sort.property">
        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Business Name'"
              :property="'business_name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'business_name')"
            />
          </template>
          <template v-slot="scope">
            <router-link
              :to="{
                name: 'vendors.details',
                params: { id: scope.row.uuid },
              }"
            >
              <span class="font-sm text-primary">
                {{ formatName(scope.row.business_name) }}</span
              ></router-link
            >
            <span class="font-xsm capitalize">{{
              formatPersona(scope.row.business_name)
            }}</span>
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ formatDeletedEmail(scope.row.name) }}</span>
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Email Address'"
              :property="'email_address'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'email_address')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ formatDeletedEmail(scope.row.email_address) }}</span>
          </template>
        </el-table-column>

        <el-table-column width="200">
          <template #header>
            <eden-table-column-header
              :label="'Vendor\'s ID'"
              :property="'vendor_id'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'vendor_id')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>

        <el-table-column width="180">
          <template #header>
            <div class="table--header">
              <span>Date Joined </span>
            </div>
          </template>
          <template v-slot="scope">
            <span class="font-sm">{{
              formatDateAndTime(scope.row.created_at, "m do, y")
            }}</span>
          </template>
        </el-table-column>

        <el-table-column width="120">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <span class="font-sm table--status">
              <el-tag :type="setType(scope.row.status)">
                {{ formatText(scope.row.status) }}
              </el-tag>
            </span>
          </template>
        </el-table-column>

        <el-table-column width="120">
          <template #header> </template>
          <template v-slot="scope">
            <active-actions
              @success="updateVendor"
              :id="scope.row.uuid"
            ></active-actions>
          </template>
        </el-table-column>
      </el-table>

      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :pageSize="per_page"
        :current-page.sync="page"
      />
    </template>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import activeActions from "./active/active-actions.vue";
import vendorRequest from "@/requests/vendors/active";
import VendorsExport from "./VendorsExport.vue";

export default {
  components: {
    activeActions,
    VendorsExport,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: false,
      sort: {
        property: "business_name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pageDataTime: "",
      exportData: [],
      searchQuery: "",
    };
  },
  computed: {
    activeVendors() {
      return this.$store.getters.vendors_active;
    },
    from() {
      return this.activeVendors.pages[this.page].from;
    },
    to() {
      return this.activeVendors.pages[this.page].to;
    },
    total() {
      return this.activeVendors.total;
    },
    per_page() {
      return this.activeVendors.per_page;
    },
    title() {
      return this.total + " Active Vendors";
    },
  },
  created() {
    this.getActiveVendors();
  },
  methods: {
    getActiveVendors() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_VENDORS_ACTIVE, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
          // this.pageDataTime = new Date();
          this.showPagination = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    updateVendor(id) {
      console.log(this.pageData.findIndex((vendor) => vendor.uuid === id));
      this.pageData.splice(
        this.pageData.findIndex((vendor) => vendor.uuid === id),
        1,
      );
      // this.pageData.splice(this.customer.index, 1);
    },
    search(query) {
      this.searchQuery = query;
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      vendorRequest
        .search(query)
        .then((response) => {
          this.pageData = response.data.data;
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.activeVendors.pages[this.page].data;
      this.showPagination = true;
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
  },
  watch: {
    page() {
      this.getActiveVendors();
    },
  },
};
</script>

<style lang="scss" scoped></style>
