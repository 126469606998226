<template>
  <div class="home">
    <eden-page-header :title="'Vendors'"> </eden-page-header>
    <eden-page-stats :title="'Overview'" :stats="stats" :disabled="true" />
    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane label="Active Vendors" name="active">
        <vendors-active v-if="tab === 'active'" />
      </el-tab-pane>
      <el-tab-pane label="Pending Vendors" name="pending">
        <vendors-pending v-if="tab === 'pending'" />
      </el-tab-pane>
      <el-tab-pane label="Inactive Vendors" name="inactive">
        <vendors-inactive v-if="tab === 'inactive'" />
      </el-tab-pane>
      <el-tab-pane label="Rejected Vendors" name="rejected">
        <vendors-rejected v-if="tab === 'rejected'" />
      </el-tab-pane>
      <el-tab-pane label="Disabled Vendors" name="disabled">
        <vendors-disabled v-if="tab === 'disabled'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import vendorsActive from "@/components/Vendors/vendors-active";
import vendorsDisabled from "@/components/Vendors/vendors-disabled.vue";
import vendorsPending from "@/components/Vendors/vendors-pending.vue";
import vendorsRejected from "@/components/Vendors/vendors-rejected.vue";
// import summary from "../../requests/vendors/summary";
import * as actions from "@/store/action-types";
import VendorsInactive from "../../components/Vendors/vendors-inactive.vue";

export default {
  name: "Customers",
  components: {
    vendorsActive,
    vendorsDisabled,
    vendorsPending,
    vendorsRejected,
    VendorsInactive,
  },
  data() {
    return {
      tab: "accepted",
    };
  },
  computed: {
    stats() {
      const summary = this.$store.getters.vendors_summary || {};
      return [
        {
          label: "Total Vendors",
          figure: this.formatFigure(summary[0]?.count),
        },
        {
          label: "Pending Vendors",
          figure: this.formatFigure(summary[1]?.count),
        },
        {
          label: "Active Vendors",
          figure: this.formatFigure(summary[2]?.count),
        },
        {
          label: "Rejected Vendors",
          figure: this.formatFigure(summary[3]?.count),
        },
        {
          label: "Disabled Vendors",
          figure: this.formatFigure(summary[4]?.count),
        },
        {
          label: "Inactive Vendors",
          figure: this.formatFigure(summary[5]?.count),
        },
      ];
    },
  },
  created() {
    let { tab } = this.$route.query;
    this.tab = tab ? tab : "active";
    if (!tab) {
      this.$router.push({
        name: "vendors.index",
        query: { tab: "active" },
      });
    }
    this.getSummary();
  },
  methods: {
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "vendors.index",
        query: { tab: tab.name },
      });
    },
    getSummary() {
      this.$store
        .dispatch(actions.GET_VENDORS_SUMMARY)
        .then(() => {
          // console.log(respone.data);
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
