<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more" />
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :command="'approve'">
            Approve Vendor</el-dropdown-item
          >
          <el-dropdown-item :command="'reject'">
            Reject Vendor</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <approve-vendor
      @close="resetAction"
      @success="$emit('success', id)"
      v-if="action.command === 'approve'"
      :should-show="action.command === 'approve'"
      :id="id"
    />
    <reject-vendor
      @close="resetAction"
      @success="$emit('success', id)"
      v-if="action.command === 'reject'"
      :id="id"
    />
  </div>
</template>

<script>
// import * as actions from "@/store/action-types";
import approveVendor from "./approve-vendor.vue";
import rejectVendor from "./reject-vendor.vue";
export default {
  name: "CustomerActions",
  props: {
    id: {
      type: String,
    },
  },
  components: { approveVendor, rejectVendor },
  data() {
    return {
      action: {
        command: "",
        visibility: false,
      },
      deleting: false,
    };
  },
  computed: {},
  methods: {
    command(cmd) {
      console.log(cmd);
      this.action.command = cmd;
      this.action.visibility = true;
    },
    resetAction() {
      this.action.command = "";
      this.action.visibility = false;
    },
  },
};
</script>

<style lang="scss">
.customer-actions {
  max-height: 200px;
  overflow-y: scroll;

  .section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    p {
      padding: 0 20px;
      margin: 10px 0;
      color: var(--eden-grey-primary);
      text-transform: uppercase;
      font-size: 0.825rem;
      font-weight: 600;
    }
  }
}
</style>
